import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from "../../resources/image/socials/logo.png";
import Footer from "../footer/Footer";
import togglebutton from "../../resources/image/projects/overview/toogle.svg";
import cross from "../../resources/image/projects/overview/cross.svg";
const Navbar = () => {
  const [toggle, settoggle] = useState(false);
  const navigate = useNavigate();
  const handletoggle = () => {
    settoggle(!toggle);
  };
  useEffect(() => {
    const _body = document.body;
    if (toggle) {
      _body.style.overflowY = "hidden";
    } else {
      _body.style.overflowY = "scroll";
    }
  }, [toggle]);
  const handleLogoClick = ()=>{
    navigate('/')
  }
  const fonthandler = (event) => {
    const link = document.getElementsByClassName("link");
    for (let index = 0; index < link.length; index++) {
      const element = link[index];
      element.classList.remove("active");
    }
    event.target.classList.add("active");
  };
  return (
    <>
      <div className=" navbar__container flex flex-row   justify-between sm:h-32  lg:px-40 sm:px-10 bg-white">
        <div className="logo__container flex justify-start items-center w-1/2 ">
          <img src={Logo} className=" md:w-20 md:h-20  sm:w-16 sm:h-16 cursor-pointer " onClick={handleLogoClick}></img>
        </div>
        <div className="lg:justify-around w-1/2 items-center text-primaryGrayColor md:flex sm:hidden md:justify-between ">
          <div className="  hover:cursor-pointer hover:font-semibold lg:text-xl sm:text-xl  m-1">
            <Link to={"/"} className="link active" onClick={fonthandler} id="project_header">
              Projects
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold lg:text-xl sm:text-xl  m-1">
            <Link to={"/about"} className="link" onClick={fonthandler}>
              My Story
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold lg:text-xl sm:text-xl  m-1">
            <Link to={"/resume"} className="link" onClick={fonthandler}>
              Resume
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold lg:text-xl sm:text-xl  m-1">
            <Link
              to={
                "https://mail.google.com/mail/?view=cm&fs=1&to=sameekshatyagi@gmail.com"
              }
              target={"_blank"}
              className="link"
              onClick={fonthandler}
            >
              Contact
            </Link>
          </div>
        </div>
        <div
          className="w-1/2 flex justify-end sm:flex md:hidden items-center "
          onClick={handletoggle}
        >
          <div>
            <img src={toggle ? cross : togglebutton}></img>
          </div>
        </div>
      </div>
      {toggle ? (
        <div className="flex-col flex    md:hidden absolute bg-secondaryWhiteColor z-10 w-full   top-32 items-center bottom-0 pt-10 h-1000p">
          <div className="  hover:cursor-pointer hover:font-semibold text-xl  m-4 text-primaryGrayColor">
            <Link to={"/"} onClick={handletoggle} id="project_header">
              Projects
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold text-xl  m-4 text-primaryGrayColor">
            <Link to={"/about"} onClick={handletoggle}>
              My Story
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold text-xl  m-4 text-primaryGrayColor">
            <Link to={"/resume"} onClick={handletoggle}>
              Resume
            </Link>
          </div>
          <div className="  hover:cursor-pointer hover:font-semibold text-xl  m-4 text-primaryGrayColor">
            <Link
              to={
                "https://mail.google.com/mail/?view=cm&fs=1&to=sameekshatyagi@gmail.com"
              }
              target={"_blank"}
              onClick={handletoggle}
            >
              Contact
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Outlet />
      <Footer />
    </>
  );
};
export default Navbar;
