import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./component/navbar/Navbar";
import Home from "./component/home/Home";

const About = lazy(() => import("./component/about/About"));
const Resume = lazy(() => import("./component/resume/Resume"));
const Contact = lazy(() => import("./component/contact/Contact"))
const Project = lazy(() => import("./component/project/Project"))
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index={true} element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/project/:index" element={<Project />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
