import React from "react";
import { Link } from "react-router-dom";
const Button = ({ text, bordercolor, textcolor, hoverbackground, hovertext, pathname ,istargetblank }) => {
  const handlewindow =()=>{
    const header = document.getElementById("project_header");
    header.classList.remove("active");
    
    window.scroll({top:0})
  }
  return (
    <div
      className={`button__container  ${bordercolor} border-2 flex sm:w-36  sm:px-0 md:px-3 lg:min-w-max sm:h-14 md:w-44 md:h-16 md:min-h-16  rounded-40  justify-center items-center ${hoverbackground} overflow-hidden sm:mt-8 `}
    >
      {
        istargetblank? <Link className={` text-${textcolor} lg:text-xl font-semibold   ${hovertext}  sm:text-base text-center  sm:w-36 md:w-full  min-h-full flex justify-center items-center  h-full`} to={pathname} target={"_blank"}>
        {text}
      </Link>:   <Link className={` text-${textcolor}  lg:text-xl font-semibold   ${hovertext} sm:text-base text-center sm:w-36 md:w-full min-h-full flex justify-center items-center h-full`} to={pathname} onClick={handlewindow} >
        {text}
      </Link>
      }
   

    </div>
  );
};

export default Button;
