import React from "react";
import Socials from "../socials/Socials";
const Footer = () => {
  return (
    <div className="footer bg-primaryGrayColor grid sm:grid-cols-1 md:grid-cols-2 gap-10 justify-between   bottom-0 sm:pt-10 sm:mt-52 md:mt-72 md:py-32">
      <div className="message flex flex-col gap-10">
        <h2 className="text-white sm:text-xl md:text-xl lg:text-2xl font-semibold ">
          I’d love to hear from you!{" "}
        </h2>
        <p className="text-white sm:text-lg lg:text-xl font-thin ">
          If you have any design opportunities or would like to brainstorm
          creative ideas, please do not hesitate to contact me.
        </p>
      </div>
      <div className="socials flex flex-col justify-around items-start gap-7">
        <div className="flex sm:justify-start md:justify-end w-full">
          {" "}
          <h2 className="text-white sm:text-lg md:text-lg lg:text-2xl">
            sameekshatyagi@gmail.com
          </h2>
        </div>
        <div className="flex  sm:justify-start md:justify-around w-full">
          <div className="md:inline-block md:w-50p sm:hidden"></div>{" "}
          <Socials
            classNames={
              "icons flex sm:flex gap-3 text-sm justify-start md:w-50p"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
