import React from "react";

import Button from "../button/Button";
import Projects from "../projects/Projects";

const Home = () => {
  return (
    <>
      <div className="home__container bg-primaryGrayColor w-full  overflow-hidden lg:max-h-800">
        <div className="flex flex-col justify-start items-start  lg:p-165 lg:gap-12  defaultpadding sm:gap-6 md:gap-10">
          <h1 className="sm:font-semibold sm:text-2xl md:text-5xl text-white p-1">
            Hi, I’m Sameeksha
          </h1>
          <h1 className="sm:font-semibold sm:text-xl lg:font-semibold lg:text-5xl text-white p-1">
          I’m a UX Designer based out of New York. I create Empathetic Designs that Enhance Experiences.
          </h1>
          <Button text={"Get in Touch"} textcolor={"white"} bordercolor={"border-white"} hoverbackground={"hover:bg-white"}
            hovertext={"hover:text-primaryGrayColor"}  pathname={"https://mail.google.com/mail/?view=cm&fs=1&to=sameekshatyagi@gmail.com"}
            istargetblank={true} />
        </div>
      </div>
      <div className=" grid grid-cols-1 mt-24 gap-10"><Projects /></div>

    </>
  );
};

export default Home;
