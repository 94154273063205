import React from "react";
import ProjectOverview from "../overview/ProjectOverview";
import { Overviewdata } from "../../resources/projects/overviewdata";
const Projects = () => {
  return (
    <>
      {Overviewdata().map((item, index) => {
        return (
          <>
          <ProjectOverview
          title={item.title}
          background = {item.background}
          image = {item.image}
          mobImage={item.mobImage}
          id={index}
        />
          </>
        );
      })}
    </>
  );
};

export default Projects;
