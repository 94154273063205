import React from 'react'
import mail from "../../resources/image/socials/mail.svg";
import instagram from "../../resources/image/socials/instagram.svg";
 
import linkedin from "../../resources/image/socials/linkedin.svg";
 
const Socials = ({classNames ,textColor}) => {
  return (
    <div className={classNames}>
    <a  href='https://mail.google.com/mail/?view=cm&fs=1&to=sameekshatyagi@gmail.com' target={"_blank"}><img src={mail} className={`${textColor}`}  /></a>
    <a  href='https://www.linkedin.com/in/sameeksha-tyagi-5439b739/' target={"_blank"}><img src={linkedin}  className={`${textColor}`}/></a>
    <a  href='http://instagram.com/bornoppenheimer' target={"_blank"}><img src={instagram}  className={`${textColor}`} /></a> 
  </div>
  )
}

export default Socials;