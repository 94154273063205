import emma from "../../resources/image/projects/overview/emma.png";
import mesh from "../../resources/image/projects/overview/mesh.png";
import cobu from "../../resources/image/projects/overview/cob.png"

//mobile 
import emmaMob from "../../resources/image/projects/overview/emmaMob.png";
import meshMob from "../../resources/image/projects/overview/meshMob.png";
import cobuMob from "../../resources/image/projects/overview/cobMob.png"
export const Overviewdata = () => {
  return [
  
    {
      title: "Mesh - Networking App for musicians",
      background: "bg-gradientPink",
      image: mesh,
      mobImage:meshMob
    },
     {
      title: "COBU - Adding a search and local directory feature ",
      background: "bg-gradientPurple",
      image: cobu,
      mobImage:cobuMob
    },
    {
      title: "Emma’s - Revamping design for local restaurant bar",
      background: "bg-gradientOrange",
      image: emma,
      mobImage:emmaMob
    },
  ];
};
