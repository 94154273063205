import React from "react";
import Button from "../button/Button";
const ProjectOverview = ({
  title,
  background,
  image,
  id,
  mobImage
}) => {
  return (
    <div className=" bg-secondaryWhiteColor    relative sm:pb-14 ">
      <div className={` grid grid-cols-1  place-content-center items-center sm:gap-2 md:gap-6 place-items-center pt-10`} key={id}>
        <div className="m-auto px-10p "> <h1 className="  text-primaryGrayColor  sm:font-semibold sm:text-2xl md:text-4xl">
                {title}
              </h1></div>
             
        <div
          className={`${background}   sm:p-10p   flex justify-center items-center md:max-h-[650px] min-w-full`} >
          <img src={image} alt="projectImage" className=" max-h-[400px] sm:hidden md:flex"></img>
          <img src={mobImage} alt="projectImage"  className="md:hidden max-h-[300px]"></img>
        </div>
        <Button
              text={"Case Study"}
              bordercolor={"border-primaryGrayColor "}
              textcolor={"primaryGrayColor"}
              pathname={`/project/${id}`}
              hoverbackground={"hover:bg-primaryGrayColor"}
              hovertext={"hover:text-white"}
              istargetblank={false}
            />
      </div>
    </div>
  );
};

export default ProjectOverview;
